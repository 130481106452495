import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import API_BASE from '../../../api/apiBase'
import API_CONFIG from '../../../api/apiConfig'

import { Select, Button, Space, notification, Row, Col, Typography, List, Alert } from 'antd'
import { Upload, message, Form, Input, Switch } from 'antd'
import { InboxOutlined, InfoCircleOutlined, DoubleLeftOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'
import * as storeActions from './store/actions/products'
import * as amazonMapsActions from '../model_maps/store/actions/amazon_maps'
import * as brandsActions from '../brands/store/actions/brands'
import * as seasonsActions from '../seasons/store/actions/seasons'
import * as vendorCodesActions from '../../configuration/vendor_codes/store/actions/vendor_codes'

import Loader from '../../common/PageElements/Loader'
import ImportFeedback from '../../common/components/ImportFeedback'

class ProductsImport extends Component {

   state = {
      action: undefined,
      saveAndClose: false,
      pageLoader: true,
      formLoader: false,
      formValues: {},
      selectedFileType: null,
      showImportResult: false,
      importResult: null,
      importStatus: null,
      brandsList: [],
      seasonsList: [],
      vendorCodesList: [],
      modelsList: [],
      componentLanguage: this.props.authState.defaultLanguage,
      tax_rates: [],
      marketplaces: [],
      import_type: [
         // {
         //    id: 1,
         //    name: 'File Prodotti per REORDERS'
         // },
         {
            id: 2,
            name: 'Aggiornamento Immagini'
         },
         // {
         //    id: 3,
         //    name: 'Aggiornamento Prezzi'
         // },
         // {
         //    id: 4,
         //    name: 'Prodotti da modello'
         // },
         {
            id: 8,
            name: 'Associazione ASIN a Vendor Code'
         },
         // {
         //    id: 9,
         //    name: 'Prodotti da file per REORDERS'
         // },
         {
            id: 10,
            name: 'Associazione SKU ad ASIN'
         },
         {
            id: 11,
            name: 'ASIN "Procurable"'
         },
         {
            id: 12,
            name: 'Prodotti'
         },
      ]
   };

   componentDidMount() {
      const formValues = {
         language: this.state.componentLanguage,
      }
      Promise.all([
         this.getBrandsList(),
         this.getSeasonsList(),
         this.getVendorCodesList(),
         this.getModelsList(),
      ]).then(() => {
         this.setState({
            formValues: formValues,
            pageLoader: false
         });
      });
   }

   getBrandsList = () => {
      return brandsActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  brandsList: response.data
               });

            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getModelsList = () => {
      return amazonMapsActions.getList()
         .then(response => {
            console.log(response);
            this.setState({
               modelsList: response.data
            });

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getSeasonsList = () => {
      return seasonsActions.getList()
         .then(response => {
            console.log(response);
            try {
               this.setState({
                  seasonsList: response.data
               });

            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getVendorCodesList = () => {
      return vendorCodesActions.getList()
         .then(response => {
            console.log(response);
            this.setState({ vendorCodesList: response.data });

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }
   getModelsList = () => {
      return amazonMapsActions.getList()
         .then(response => {
            console.log(response);
            this.setState({
               modelsList: response.data
            });

         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   // componentDidMount() {
   //    brandsActions.getList()
   //       .then(response => {
   //          console.log(response);
   //          try {
   //             const formValues = {
   //                language: this.state.componentLanguage
   //             }
   //             this.setState({ brandsList: response.data, formValues: formValues, pageLoader: false });
   //          } catch (e) {
   //             this.setState({ pageLoader: false });
   //             notification.open({
   //                message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //                description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
   //                icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //             })
   //             if (this.props.commonState.debug) console.log(e);
   //          }

   //       }).catch((e) => {
   //          this.setState({ pageLoader: false });
   //          notification.open({
   //             message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
   //             description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
   //             icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
   //          })
   //          if (this.props.commonState.debug) console.log(e);
   //       });
   // }

   handleSaveAndClose = () => {
      this.setState({ saveAndClose: true });
   }

   setFileType = (value) => {
      console.log(value)
      this.setState({ selectedFileType: value })
   }

   onFinish = values => {
      console.log(values);
      this.setState({ formLoader: true });
      const data = {};
      data.language = values.language;
      data.file_path = values.fileImport[0].response.data.file_path;
      data.file_name = values.fileImport[0].response.data.file_name;
      data.file_type = values.file_type;
      data.overwrite_empty = values.overwrite_empty;
      data.brand_id = values.brand_id;
      data.vendor_code_id = values.vendor_code_id;
      data.amazon_map_id = values.amazon_map_id;
      data.default_season = values.season_id;
      data.language_id = 1; //TODO: improve language management
      data.nis_ria = values.nis_ria
      if (!data.file_path || !data.file_name) {
         this.setState({ formLoader: false, draggerDisable: false });
         notification.open({
            message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
            description: languageManager.getMessage(this.props.commonState, 'notification.error.dataNotValid'),
            icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
         })
      }
      else {
         storeActions.importCSVProducts(data)
            .then(response => {
               console.log(response);
               // this.props.history.goBack();
               this.setState({ showImportResult: true, importResult: response.data, importStatus: response.status, formLoader: false, draggerDisable: false });

               notification.success({
                  message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
                  description: languageManager.getMessage(this.props.commonState, this.state.action === 'edit' ? 'notification.confirm.updateOk' : 'notification.confirm.insertOk'),
               })

            }).catch((e) => {
               this.setState({ formLoader: false, draggerDisable: false });
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: e.error ? (
                     <List
                        size="small"
                        split={false}
                        dataSource={e.error}
                        renderItem={item => <List.Item>{item}</List.Item>}
                     />
                  ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
                  duration: 0
               })
               if (this.props.commonState.debug) console.log(e);
            });
      }
   };

   onFinishFailed = errorInfo => {
      notification.error({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.error.formInvalid'),
      })
   };

   setDefaultLanguageHandler = (value) => {
      console.log(value);
      const updatedValues = this.setForm(this.state.response, value);
      this.setState({ formValues: updatedValues, componentLanguage: value })
   }


   render() {
      if (this.state.pageLoader) return <Loader />;
      const { Title } = Typography
      const { Option } = Select
      const { Dragger } = Upload
      const layout = {
         // layout: 'inline',
         labelCol: { span: 8 },
         wrapperCol: { span: 16 },
      };

      const scope = this
      const draggerProps = {
         name: 'file',
         multiple: false,
         disabled: this.state.draggerDisable,
         action: API_BASE.url + API_CONFIG.appUrls.files.fileImport,
         onChange(info) {
            scope.setState({ draggerDisable: info.fileList.length });
            console.log("CHANGE: ", info);
            const { status } = info.file;
            if (status !== 'uploading') {
               console.log(info.file, info.fileList);
            }
            if (status === 'done') {
               message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
               message.error(`${info.file.name} file upload failed.`);
            }
         },
      };

      const normFile = (e) => {
         if (Array.isArray(e)) {
            return e;
         }
         return e && e.fileList;
      };
      let form = (
         <Form
            {...layout}
            id="importFile"
            initialValues={this.state.formValues}
            onFinish={this.onFinish}
            onFinishFailed={this.onFinishFailed}
         >
            <Form.Item
               name="fileImport"
               rules={[{ required: true, message: 'Scegli un file' }]}
               valuePropName='fileList'
               getValueFromEvent={normFile}
               colon={false}
               wrapperCol={24}
            >
               <Dragger {...draggerProps}>
                  <p className="ant-upload-drag-icon">
                     <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                     Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                     band files
                  </p>
               </Dragger>
            </Form.Item>
            {/* <Form.Item
               label="Brand"
               name="brand_id"
               rules={[{ required: true, message: 'Seleziona il brand' }]}
            >
               <Select
                  showSearch
                  size="medium"
                  // className="width-70px-I"
                  placeholder="Seleziona il brand"
                  optionFilterProp="children"
               >
                  {
                     this.state.brandsList.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>
            </Form.Item> */}
            <Form.Item
               label="Sovrascrivi i campi vuoti"
               name="overwrite_empty"
               valuePropName="checked"
            >
               <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
               />
            </Form.Item>
            <Form.Item
               label="Tipo di file"
               name="file_type"
               rules={[{ required: true, message: 'Scegli il tipo di file' }]}
            >
               <Select
                  showSearch
                  size="medium"
                  // className="width-70px-I"
                  placeholder="Seleziona il tipo di file"
                  optionFilterProp="children"
                  onChange={this.setFileType}
               >
                  {
                     this.state.import_type.map((map) => {
                        return <Option key={map.id} value={map.id}>{map.name}</Option>
                     })
                  }
               </Select>
            </Form.Item>
            {
               this.state.selectedFileType === 8 ? ([
                  <Form.Item
                     label="Vendor Code"
                     name="vendor_code_id"
                     key="vendor_code_id"
                     rules={[{ required: true, message: 'Scegli il Vendor Code a cui associare i prodotti' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        // className="width-70px-I"
                        placeholder="Seleziona il Vendor Code"
                        optionFilterProp="children"
                     >
                        {
                           this.state.vendorCodesList.map((code) => {
                              return <Option key={code.id} value={code.id}>{code.code}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>,
               ]) : null
            }
            {
               this.state.selectedFileType === 9 ? ([
                  <Form.Item
                     label="NIS/REORDERS"
                     name="nis_ria"
                     key="nis_ria"
                     rules={[{ required: true, message: 'Scegli il tipo di prodotto' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        placeholder="Seleziona il tipo"
                        optionFilterProp="children"
                     >
                        <Option value={1}>NIS</Option>
                        <Option value={2}>REORDERS</Option>
                     </Select>
                  </Form.Item>,
                  <Form.Item
                     label="Lingua"
                     name="language_id"
                     key="language_id"
                     rules={[{ required: true, message: 'Scegli la lingua del file' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        placeholder="Seleziona la lingua"
                        optionFilterProp="children"
                     >
                        {
                           this.props.authState.userData.languages.map((lang) => {
                              return <Option key={lang.value} value={lang.value}>{lang.name + ' (' + lang.label + ')'}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>,
               ]) : null
            }
            {
               this.state.selectedFileType === 12 ? ([
                  <Form.Item
                     label="Stagione"
                     name="season_id"
                     key="season_id"
                     rules={[{ required: true, message: 'Scegli la stagione dei prodotti' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        // className="width-70px-I"
                        placeholder="Seleziona la stagione"
                        optionFilterProp="children"
                     >
                        {
                           this.state.seasonsList.map((season) => {
                              return <Option key={season.id} value={season.id}>{season.name}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>,
                  <Form.Item
                     label="Lingua"
                     name="language_id"
                     key="language_id"
                     rules={[{ required: true, message: 'Scegli la lingua del file' }]}
                  >
                     <Select
                        showSearch
                        size="medium"
                        placeholder="Seleziona la lingua"
                        optionFilterProp="children"
                     >
                        {
                           this.props.authState.userData.languages.map((lang) => {
                              return <Option key={lang.value} value={lang.value}>{lang.name + ' (' + lang.label + ')'}</Option>
                           })
                        }
                     </Select>
                  </Form.Item>
               ]) : null
            }
            <Form.Item label="language" name="language" noStyle><Input type="hidden" /></Form.Item>
         </Form>
      );
      const importFeedback = (
         <ImportFeedback
            key='importFeedback'
            importResult={this.state.importResult}
            importStatus={this.state.importStatus}
            visible={this.state.showImportResult} />
      )


      // const specific_prices_columns = [
      //    {
      //    title: 'Nazione',
      //    dataIndex: 'country',
      //    key: 'country'
      //    },
      //    {
      //       title: 'Ecommerce',
      //       dataIndex: 
      //    }
      // ]


      const pageContent = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.products.import')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space size='small'>
                        <Select
                           showSearch
                           className="width-70px-I"
                           placeholder="Seleziona la lingua"
                           defaultValue={this.state.componentLanguage}
                           optionFilterProp="children"
                           onChange={this.setDefaultLanguageHandler}
                        >
                           {
                              this.props.authState.userData.languages.map((lang) => {
                                 return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                              })
                           }
                        </Select>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {importFeedback}
               {form}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/products/list" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                        <Button form="importFile" htmlType="reset" disabled={this.state.formLoader}>Annulla</Button>
                        <Button form="importFile" type="primary" htmlType="submit" loading={this.state.formLoader}>Salva</Button>
                        <Button form="importFile" type="primary" htmlType="submit" loading={this.state.formLoader} onClick={this.handleSaveAndClose}>Salva e chiudi</Button>
                     </Space>
                  </Col>
               </Row>
            </div>
         </div>
      );
      const helpButton = {
         title: "Aiuto"
      }

      const buttons = [];
      buttons.push(helpButton);

      console.log(this.state);
      // this.props.action === 'edit' ? (
      //    initial_values = {
      //       name: this.props.itemData.t_name,
      //       percentage: this.props.itemData.percentage
      //    }) : null;

      return (
         <div className="container-fluid">
            <div className="row">
               <div className="col">
                  {pageContent}
               </div>
            </div>
         </div>

      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(ProductsImport);