import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class ProgressivesApi {

   static getModel() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getModel)
            .then(response => {
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getList)
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getListExtended, { ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static duplicateItem(itemId, onlyMissing) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.duplicateItem, {
            id: itemId,
            only_missing: onlyMissing
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error)
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               console.log(decodedString)
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            })
      });
   }
   static confirmItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.confirmItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static restoreItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.restoreItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static cancelItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.cancelItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static mergeItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.mergeItems, {
            ids: itemIds,
            return_list: return_list
         },
            {
               responseType: 'arraybuffer'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               console.log(error)
               const decodedString = String.fromCharCode.apply(null, new Uint8Array(error));
               console.log(decodedString)
               const obj = JSON.parse(decodedString);
               // console.log(obj)
               reject(obj)
            })
      });
   }

   static sendToAmazon(itemId) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.sendToAmazon, {
            id: itemId,
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.marketplacesCountries.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.vendorCodes.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getTypesList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getGroupsList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getCategoriesList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.getQuartersList),
         ])
            .then(Axios.spread((countriesList, vendorCodesList, brandsList, suppliersList, customersList, warehousesList, typesList, groupsList, categoriesList, quartersList) => {
               const response = {
                  countriesList: countriesList,
                  vendorCodesList: vendorCodesList,
                  brandsList: brandsList,
                  suppliersList: suppliersList,
                  customersList: customersList,
                  warehousesList: warehousesList,
                  typesList: typesList,
                  groupsList: groupsList,
                  categoriesList: categoriesList,
                  quartersList: quartersList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static importFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.importFromFile, { ...formData })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static fileImport(data) {
      console.log(data.file)
      return new Promise((resolve, reject) => {
         let formData = new FormData;
         formData.append('file', data.file)
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.fileImport, formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static exportExcel(filename, itemIds) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.progressives.exportExcel, { filename: filename, item_ids: itemIds },
            {
               responseType: 'blob'
            })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default ProgressivesApi