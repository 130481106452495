import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, Card } from "reactstrap"
import { Link } from "react-router-dom"
import _ from 'lodash'

import { notification, Button } from 'antd'

import languageManager from '../../services/languageManager'

import * as authenticationAction from '../../store/actions/authenticationAction'

// import images
import bg from "../../assets/images/bg.jpg"
import logoDark from "../../assets/images/nzt_dark.png"

class PageLogin extends Component {
   state = {
      email: '',
      password: '',
      loginLoader: false,
   }

   updateLocalState = (event) => {
      // console.log(event.target.name);
      // console.log(event.target.value);
      const state = { ...this.state };
      state[event.target.name] = event.target.value;
      this.setState(state);
   };

   /**
    *
    */
   login = () => {
      if (this.validate()) {
         this.setState({ loginLoader: true })
         this.props.userLogin({
            'email': this.state.email,
            'password': this.state.password
         }).then(x => {
            // console.dir(x);
            this.props.history.push('/admin');
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.confirm.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.confirm.loginSuccess'),
            });
         }).catch(err => {
            this.setState({ loginLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.warning.loginFailed'),
            });
         });
         return;
      }

      this.setState({ loginLoader: false })
      notification.error({
         message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
         description: languageManager.getMessage(this.props.commonState, 'notification.warning.invalidLoginData'),
      });
   };

   /**
    *
    * @returns {boolean}
    */
   validate = () => {
      return !_.isEmpty(this.state.email) &&
         !_.isEmpty(this.state.password);
   };

   onKeyUp = (event) => {
      if (event.key === "Enter") {
         this.login();
      }
   }



   render() {
      return (
         <React.Fragment>
            {" "}
            <div
               className="accountbg"
               style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url(${bg})`
               }}
            ></div>
            <div className="wrapper-page account-page-full">
               <Card className="shadow-none">
                  <div className="card-block">
                     <div className="account-box">
                        <div className="card-box shadow-none p-4">
                           <div className="p-2">
                              <div className="text-center mt-4">
                                 <Link to="/">
                                    <img src={logoDark} height="50" alt="logo" />
                                 </Link>
                              </div>

                              <h4 className="font-size-18 mt-5 text-center">
                                 {languageManager.getMessage(this.props.commonState, 'welcome')}!
                              </h4>
                              {/* <p className="text-muted text-center">
                                 Sign in to continue to Veltrix.
                              </p> */}

                              <div className="form-group">
                                 <label htmlFor="email">Email</label>
                                 <input
                                    type="email"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={this.state.email}
                                    onChange={this.updateLocalState}
                                    onKeyUp={this.onKeyUp}
                                 />
                              </div>

                              <div className="form-group">
                                 <label htmlFor="password">Password</label>
                                 <input
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    name="password"
                                    placeholder="Enter password"
                                    value={this.state.password}
                                    onChange={this.updateLocalState}
                                    onKeyUp={this.onKeyUp}
                                 />
                              </div>

                              <Row className="form-group">
                                 {/* <Col sm={6}>
                                    <div className="custom-control custom-checkbox">
                                       <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="customControlInline"
                                       />
                                       <label
                                          className="custom-control-label"
                                          htmlFor="customControlInline"
                                       >
                                          Remember me
                                          </label>
                                    </div>
                                 </Col> */}

                                 <Col sm="12" className="text-right">
                                    <Button
                                       type='primary'
                                       onClick={this.login}
                                       loading={this.state.loginLoader}
                                    >
                                       {languageManager.getMessage(this.props.commonState, 'widget.login.button')}
                                    </Button>
                                 </Col>
                              </Row>

                              {/* <Row className="form-group mt-2 mb-0">
                                 <div className="col-12 mt-3">
                                    <Link to="pages-recoverpw-2">
                                       <i className="mdi mdi-lock"></i> {languageManager.getMessage(this.props.commonState, 'widget.login.password_forgot')}
                                    </Link>
                                 </div>
                              </Row> */}

                              <div className="mt-5 pt-4 text-center">
                                 {/* <p>
                                    Don't have an account ?{" "}
                                    <Link
                                       to="pages-register-2"
                                       className="font-weight-medium text-primary"
                                    >
                                       {" "}
                          Signup now{" "}
                                    </Link>{" "}
                                 </p> */}
                                 <p>
                                    © {new Date().getFullYear()} NZT VENDOR DEMO.<br />Made with{" "}
                                    <i className="mdi mdi-heart text-danger"></i> by NZT Solutions srl
                                 </p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </Card>
            </div>
         </React.Fragment>
      );
   }
}

const mapStateToProps = state => {
   return {
      commonState: state.commonState
   };
};

function mapDispatchToProps(dispatch) {
   return {
      userLogin: (userData) => dispatch(authenticationAction.login(userData))
   };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageLogin);
