import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';

class WarehousesApi {

   static getList() {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList)
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getListExtended, { ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
         ])
            .then(Axios.spread((warehousesList, brandsList) => {
               const response = {
                  warehousesList: warehousesList,
                  brandsList: brandsList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static emptyItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.emptyItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static emptyBrandItems(warehouseId, brandId, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.emptyBrandItems, {
            warehouse_id: warehouseId,
            brand_id: brandId,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

}

export default WarehousesApi