import API_BASE from './apiBase';
import API_CONFIG from './apiConfig';
import Axios from 'axios';
import { PurchaseOrderTypeEnum } from '../shared/enums';

class UniqueFilesApi {

   static getRiaList(year, week) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getList, {
            order_type: PurchaseOrderTypeEnum.REORDERS,
            year: year,
            week: week
         })
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getRiaLines(uniquefileId, brandId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getLinesExtended, {
            unique_file_id: uniquefileId,
            brand_id: brandId,
            ...params,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   
   static getSeasonalList(year, week) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getList, {
            order_type: PurchaseOrderTypeEnum.SEASONAL,
            year: year,
            week: week
         })
            .then(response => {
               // console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static getSeasonalLines(uniquefileId, brandId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getLinesExtended, {
            unique_file_id: uniquefileId,
            brand_id: brandId,
            ...params,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getListExtended(type, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getListExtended, { type: type, ...params, filter_values: JSON.stringify(filterValues) })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getItem(itemId, params, filterValues) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.getItem, {
            ...params,
            id: itemId,
            filter_values: JSON.stringify(filterValues)
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static createOrUpdate(itemData,) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.createOrUpdate, {
            ...itemData
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static cancelItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.cancelItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }
   static deleteItems(itemIds, return_list) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.deleteItems, {
            ids: itemIds,
            return_list: return_list
         })
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            });
      });
   }

   static getComplements() {
      return new Promise((resolve, reject) => {
         Axios.all([
            Axios.post(API_BASE.url + API_CONFIG.appUrls.brands.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.suppliers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.customers.getList),
            Axios.post(API_BASE.url + API_CONFIG.appUrls.warehouses.getList),
            // Axios.post(API_BASE.url + API_CONFIG.appUrls.uniquefiles.getTypesList),
            // Axios.post(API_BASE.url + API_CONFIG.appUrls.uniquefiles.getGroupsList),
            // Axios.post(API_BASE.url + API_CONFIG.appUrls.uniquefiles.getCategoriesList),
            // Axios.post(API_BASE.url + API_CONFIG.appUrls.uniquefiles.getQuartersList),
         ])
            .then(Axios.spread((brandsList, suppliersList, customersList, warehousesList, typesList, groupsList, categoriesList, quartersList) => {
               const response = {
                  brandsList: brandsList,
                  suppliersList: suppliersList,
                  customersList: customersList,
                  warehousesList: warehousesList,
                  // typesList: typesList,
                  // groupsList: groupsList,
                  // categoriesList: categoriesList,
                  // quartersList: quartersList,
               }
               resolve(response);
            }))
            .catch(error => {
               reject(error);
            });
      });
   }

   static importFromFile(formData) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.importFromFile, { ...formData })
            .then(response => {
               console.log(response)
               resolve(response)
            })
            .catch(error => {
               reject(error)
            });
      });
   }

   static exportFileRIA(uniquefileId, filename) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.exportFileRIA, {
            unique_file_id: uniquefileId,
            filename: filename,
         },
            {
               responseType: 'blob'
            }
         )
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            })

      });
   }
   static exportFileSEASONAL(uniquefileId, filename) {
      return new Promise((resolve, reject) => {
         Axios.post(API_BASE.url + API_CONFIG.appUrls.uniqueFiles.exportFileSEASONAL, {
            unique_file_id: uniquefileId,
            filename: filename,
         },
            {
               responseType: 'blob'
            }
         )
            .then(response => {
               console.log(response);
               resolve(response);
            })
            .catch(error => {
               reject(error);
            })

      });
   }

}

export default UniqueFilesApi